import React, { useEffect, useState } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';

//Translations
const NEW_VERSION = 'ServiceWorker.UpdateIsAvailable';

const ServiceWorkerPopup = props => {
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const onSWUpdate = registration => {
    document.getElementById("message").style.display = 'block';
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    document.getElementById("message").style.display = 'none';
    window.location.reload();
  };

  return (
    <div id="message" className="message">
      <span className="closebtn" onClick={reloadPage}>
        OK
      </span>
      <strong>{props.t(NEW_VERSION)}</strong>
    </div>
  );
}

export default ServiceWorkerPopup;