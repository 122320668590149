//Home Images
import home1 from './home/home_1.jpg';
import home2 from './home/home_2.jpg';
import home3 from './home/home_3.jpg';
import home4 from './home/home_4.jpg';

/*//User Card Images
import memberCard from './user-card/member_card.png';
import missingCard from './user-card/missing_card.png';
*/
//Info Images
import info1 from './info/info1.jpg';
import info2 from './info/info2.jpeg';
import info3 from './info/info3.jpg';

//Menu Icons
import home from './menu-icons/accueil.png';
import activities from './menu-icons/activities.png';
import collections from './menu-icons/collections.png';
import exhibitions from './menu-icons/expositions.png';
import infos from './menu-icons/information.png';
import map from './menu-icons/plan.png';
import ticket from './menu-icons/ticket.png';

//Navigation Icons
import arrowDown from './navigation-icons/arrow-down.svg';
import arrowLeft from './navigation-icons/arrow-left.svg';
import close from './navigation-icons/close.png';
import logo from './navigation-icons/logo-mcq.svg';
import menu from './navigation-icons/menu.png';

//Popup Icons
import iosShare from './Popup/img_ios-share.jpg';
import iosLogo from './Popup/img_logo-mcq-ios.png';
import androidClose from './Popup/img_android-close.svg';

//Media Icons
import audio from './media/audio.png';
import video from './media/video.png';

export const HomeImages = [
  { image: home1 },
  { image: home2 },
  { image: home3 },
  { image: home4 }
];
/*
export const UserCardImages = [
  { memberCard: memberCard },
  { missingCard: missingCard }
];
*/
export const InfoImages = [
  { image: info1 },
  { image: info2 },
  { image: info3 }
];

export const MenuIcons = [
  { image: home },
  { image: activities },
  { image: collections },
  { image: exhibitions },
  { image: infos },
  { image: map },
  { image: ticket }
];

export const NavigationIcons = [
  { image: arrowDown },
  { image: arrowLeft },
  { image: close },
  { image: logo },
  { image: menu },
];

export const PopupIcons = [
  { image: iosLogo },
  { image: iosShare },
  { image: androidClose }
]

export const MediaIcons = [
  { image: audio },
  { image: video }
]