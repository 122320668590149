import React from 'react';

//Translations
const NOT_WIFIED = 'General.Wifi.NotCorrectWifi';

const WifiPopup = props => {
  const okClick = () => {
    document.getElementById("message-wifi").style.display = 'none';
  };

  return (
    <div id="message-wifi" className="message">
      <span className="closebtn" onClick={okClick}>
        OK
      </span>
      <strong>{props.t(NOT_WIFIED)}</strong>
    </div>
  )
};

export default WifiPopup;