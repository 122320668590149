//import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { DidomiSDK } from "@didomi/react";

const HelmetContent = ({ siteLang, setYoutubeConsent, setVimeoConsent }) => {
  const didomiConfig = {
    user: {
      bots: {
        consentRequired: false,
        types: ['crawlers', 'performance'],
      }
    },
    languages: {
      enabled: [siteLang.includes("en") ? "en" : "fr"],
      default: siteLang.includes("en") ? "en" : "fr",
    },
  };

  const consentHasChanged = () => {
    const Didomi = window.Didomi;

    if (Didomi) {
      const youtubeConsent =
        Didomi.getUserConsentStatusForVendor("c:youtube-wwikjhN6");
      const viemoConsent =
        Didomi.getUserConsentStatusForVendor("c:vimeo-EUQqEFqV");

      setYoutubeConsent(youtubeConsent);
      setVimeoConsent(viemoConsent);
    }
  };

  /*
  useEffect(() => {
    console.log(siteLang);
  }, [siteLang]);
  */

  return (
    <>
      <Helmet htmlAttributes={{ lang: siteLang }}>
        <script
          type="didomi/javascript"
          data-vendor="c:vimeo-EUQqEFqV"
          src="https://player.vimeo.com/api/player.js"
        ></script>
        <script
          type="didomi/javascript"
          data-vendor="c:youtube-wwikjhN6"
          src="https://www.youtube.com/iframe_api"
        ></script>
      </Helmet>

      <DidomiSDK
        config={didomiConfig}
        apiKey={process.env.REACT_APP_DIDOMI_API_PUBLIC_KEY}
        noticeId={process.env.REACT_APP_DIDOMI_CONSENT_NOTICE_ID}
        onReady={consentHasChanged}
        onConsentChanged={consentHasChanged}
      />
    </>
  );
};

export default HelmetContent;
