import React, { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Routes as Switch,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import getCookie from "./functions/getCookie";

import { PopupIcons } from "./assets/images/Images";
import ServiceWorkerWrapper from "./components/ServiceWorkerPopup/ServiceWorkerPopup";
import FallbackLoader from "./components/FallbackLoader/FallbackLoader";
import WifiPopup from "./components/WifiPopup/WifiPopup";
import HelmetContent from "./HelmetContent";

// Constants
const DESKTOP_POPUP = "General.Popup.Desktop";
const LANDSCAPE_POPUP = "General.Popup.Landscape";

// Containers
const Skeleton = lazy(() => import("./layout/Skeleton/Skeleton"));

// Pages
const InteractiveGame = lazy(() =>
  import("./pages/Exhibition/InteractiveGame/InteractiveGame")
);

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lngIndex, setLngIndex] = useState(1);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [userConsentForYoutube, setUserConsentForYoutube] = useState(false);
  const [userConsentForVimeo, setUserConsentForVimeo] = useState(false);

  useEffect(() => {
    const lng = i18n.language;
    const handleInstallPrompt = (e) => {
      e.preventDefault();
      let cookieBlockInstallCookieHide = getCookie("BlockInstallCookieHide");

      if (!cookieBlockInstallCookieHide) {
        setDeferredPrompt(e);
      }
    };

    if (lng.includes("fr")) {
      setLngIndex(0);
    } else {
      setLngIndex(1);
    }

    window.addEventListener("beforeinstallprompt", handleInstallPrompt);

    return () =>
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);
  }, [i18n.language]);

  // Initialize deferredPrompt for use later to show browser install prompt.
  //let deferredPrompt;

  return (
    <div className="app">
      <HelmetContent
        siteLang={i18n.language}
        setYoutubeConsent={setUserConsentForYoutube}
        setVimeoConsent={setUserConsentForVimeo}
      />
      {isMobile && (
        <>
          <ServiceWorkerWrapper t={t} />
          <WifiPopup t={t} />
          <Suspense fallback={<FallbackLoader />}>
            <Switch>
              <Route
                exact
                path="/interactive-game"
                name="Interactive Game"
                element={
                  <InteractiveGame
                    t={t}
                    navigate={navigate}
                    location={location}
                    lngIndex={lngIndex}
                  />
                }
              />
              <Route
                path="*"
                name="Default"
                element={
                  <Skeleton
                    t={t}
                    i18n={i18n}
                    lngIndex={lngIndex}
                    location={location}
                    navigate={navigate}
                    deferredPrompt={deferredPrompt}
                    youtubeConsent={userConsentForYoutube}
                    vimeoConsent={userConsentForVimeo}
                  />
                }
              />
            </Switch>
          </Suspense>

          <div className="message-orientation" id="message-orientation-id">
            {t(LANDSCAPE_POPUP)}
          </div>
        </>
      )}
      {!isMobile && (
        <div className="desktop-screen">
          <h1>{t(DESKTOP_POPUP)}</h1>
          <img
            className="logo-mc-desktop"
            src={PopupIcons[0].image}
            alt="Musée de la civilisation"
          />
        </div>
      )}
    </div>
  );
};

export default App;
